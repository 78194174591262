<ion-card tappable>
    <ion-card-content>
      <ion-avatar *ngIf="company.company_logo">
        <img *ngIf="company.company_logo" width="48" src="{{ aws.cloudinaryUrl + company.company_logo }}"
             (error)="loadLogo($event,company)">
      </ion-avatar>

      <ion-label class="ion-text-wrap">
        <h2 *ngIf="company.company_common_name_en">{{ company.company_common_name_en }}
          <ion-icon class="active" name="checkmark-circle"  *ngIf="company.company_approved_to_hire"></ion-icon></h2>
        <h2 *ngIf="!company.company_common_name_en">{{ company.company_name }}</h2>
        
        <div class="small-detail">
          <ng-container *ngIf="page =='follow'">
            <p>@ {{ toDate(company.company_last_followup_datetime) | date: 'MMM d, y, h:mm:ss a' }}</p>
            <p>{{ company.company_last_followup_datetime | timeAgo }}</p>
          
            <p *ngIf="company.company_next_followup_datetime">
              Next followup @ {{ toDate(company.company_next_followup_datetime) | date: 'MMM d, y, h:mm:ss a' }}
            </p>
          </ng-container>
        </div>

        <p *ngIf="company.company_description_en">{{stripTag(company.company_description_en)}}</p>
        
        <p *ngIf="!company.company_description_en">{{company.company_name}}</p>

        <ion-badge class="company_status_badge danger" slot="start" *ngIf="!company.company_status && !company.parent_company_id">
          Inactive
        </ion-badge>
        
        <ion-badge class="company_status_badge success" slot="start" *ngIf="company.company_status !== 9 && !company.parent_company_id">
          Active
        </ion-badge>
        
        <ion-badge class="company_status_badge success" slot="start" *ngIf="company.company_status === 9 && !company.parent_company_id">
          Under Review
        </ion-badge>

        <!--
        <ion-badge slot="start" color="light" *ngIf="totalCandidates > 0">
          {{ totalCandidates }} staff
        </ion-badge>-->

        <ion-badge slot="start" color="light" *ngIf="company.subCompanies && company.subCompanies.length > 0">
          {{ company.subCompanies.length }} sub-companies
        </ion-badge>

        <ion-badge slot="start" color="light" *ngIf="company.stores && company.stores.length > 0">
          {{ company.stores.length }} stores
        </ion-badge>

        <ion-badge slot="start" color="light" *ngIf="company.brands && company.brands.length > 0">
          {{ company.brands.length }} brands
        </ion-badge>

        <!-- company.total_candidate -->
        <ion-badge slot="start" color="light" *ngIf="totalCandidates > 0 && !company.transferInLast40Days">
          40 days passed without payment
        </ion-badge>
        
        <ion-badge slot="start" color="light" *ngIf="totalCandidates > 0" tappable (click)="viewAssigned(company, $event)">
          {{ totalCandidates }} Assigned Candidate
        </ion-badge>

        <ion-badge slot="start" color="light" *ngIf="company.total_suggestions > 0">
          {{ company.total_suggestions }} total Suggestions
        </ion-badge>

        <ion-badge slot="start" color="warning" *ngIf="!company.company_approved_to_hire">
          <ion-icon name="close-outline"></ion-icon> Not approved to hire
        </ion-badge>
      </ion-label>
    </ion-card-content>
      <ion-grid>
          <ion-row>
            <ion-col><ion-img src="assets/images/doller.svg" ></ion-img> {{company.company_hourly_rate | number:'1.0-3' }} {{ company.currency_code }} / hour</ion-col>
            <ion-col><ion-img src="assets/images/money.svg" ></ion-img>
                <span *ngIf="company.company_bonus_commission">{{company.company_bonus_commission | number:'1.0-2' }} %</span>
                <span *ngIf="!company.company_bonus_commission">0 %</span>
            </ion-col>
          </ion-row>
      </ion-grid>
</ion-card>
