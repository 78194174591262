<ion-header mode="md" [class.add-border]="borderLimit" mode="md">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button><ion-icon class="setting-icon" name="ellipsis-vertical-outline"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar class="container" *ngIf="company && company.subCompanies">
    <ion-title class="main" size="large"><strong>Subcompanies</strong></ion-title>
    <ion-title class="secondary" size="small" *ngIf="company">
      {{ (company.company_common_name_en) ?  company.company_common_name_en:company.company_name }}
    </ion-title>
  </ion-toolbar>
  
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">

    <ion-list *ngIf="company && company.subCompanies">
      <app-company [company]="company" *ngFor="let company of company.subCompanies" (click)="rowSelected(company)">
      </app-company>
    </ion-list>

    <no-items *ngIf="!loading && company.subCompanies && company.subCompanies.length ==0" message="~ No subcompany data ~"></no-items>

    <loading-modal [loading]="loading" type="company-list"></loading-modal>
 
  </div> 

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="create()">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
