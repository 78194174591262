<ion-header mode="md">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar class="container title-toolbar-wrapper">
    <ion-title>{{ pageTitle }}</ion-title>

    <ion-buttons slot="end">
      <ion-button clear class="cancel" color="primary" (click)="close()">
        Cancel
      </ion-button>
      <ion-button *ngIf="segment == 'manual'" [disabled]="!form.valid || (total <= 0)" (click)="validate()">
        Save
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar class="container">

    <ion-item class="input-wrapper txt-date-range">
      <div tabindex="0"></div>
      <ion-label position="floating">Time Period</ion-label>
      <ion-input [value]="range" (click)="openCalendarPopup($event)"></ion-input>
      <ion-icon src="assets/images/icon-date-range.svg"></ion-icon>
    </ion-item>

    <ion-segment mode="md" [(ngModel)]="segment" (ionChange)="segmentChanged($event)">
      <ion-segment-button mode="md" value="excel-sheet-upload">
        <ion-label>Excel Sheet Upload</ion-label>
      </ion-segment-button>
    
      <ion-segment-button mode="md" value="manual">
        <ion-label>Manual Transfer</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
    <ng-container *ngIf="segment == 'manual'">

      <ion-button class="btn-approved-work-log" *ngIf="ready" (click)="approvedWorkLog()" [disabled]="!startDate || !endDate">
        Set approved work log for provided date range 
      </ion-button>

      <form [formGroup]="form" *ngIf="ready">

        <!-- store_id pipe returns the transferCandidates grouped by store as object of following format: -->
        <!-- {name: string, resources: TransferCandidate[]} -->
        <ion-item-group *ngFor="let group of transfer.transferCandidates | store_name">

          <ion-item-divider><b>{{ group.name }}</b></ion-item-divider>

          <ion-card *ngFor="let transferCandidateRecord of group.resources"
            id="candidate_{{ transferCandidateRecord.candidate.candidate_id }}">

            <ion-item>
              <div tabindex="0"></div>
              <ion-avatar slot="start">
                <ion-img *ngIf="!transferCandidateRecord.candidate.candidate_personal_photo" src="/assets/icon/icon.png">
                </ion-img>
                <ion-img (ionError)="onImageError(transferCandidateRecord.candidate)"
                  *ngIf="transferCandidateRecord.candidate.candidate_personal_photo"
                  src="{{ awsService.cloudinaryUrl + transferCandidateRecord.candidate.candidate_personal_photo }}"></ion-img>
              </ion-avatar>
              <ion-label>
                <h5 class="candidate_name">{{ transferCandidateRecord.candidate.candidate_name?
                  transferCandidateRecord.candidate.candidate_name: transferCandidateRecord.candidate.candidate_name_ar }} 
                  <ion-badge color="danger" *ngIf="transferCandidateRecord.candidate.civilExpired">Civil Expired</ion-badge>
                </h5>
                <p>
                  {{ getCompanyHourlyRate(transferCandidateRecord) | number:'1.0-3' }} {{ form.value.currency_code }} per hour 
                  <ng-container *ngIf="transferCandidateRecord.transfer_cost > 0">
                    + {{ transferCandidateRecord.transfer_cost }} {{ form.value.currency_code }} transfer cost
                  </ng-container>  
                </p>
              </ion-label>
              
            </ion-item>

            <ion-grid>
              <ion-row>
                <!-- Hours Input Column -->
                <ion-col size-lg="4" size-md="4" size-sm="12" size-xs="12">
                  <ion-item class="input-wrapper">

                    <ion-label position="floating">
                      Hours 
                      <span class="error" *ngIf="parseNumber(transferCandidateRecord.hours) < 0">Invalid value</span>
                    </ion-label>

                    <!-- [readonly]="transferCandidateRecord.candidate.civilExpired" -->
                    <ion-input type="number" inputmode="numeric" min="0" (ionChange)="calculateTotal()"
                      [(ngModel)]="transferCandidateRecord.hours"
                      formControlName="hours[{{transferCandidateRecord.candidate.candidate_id}}]">
                    </ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size-lg="4" size-md="4" size-sm="12" size-xs="12">
                  <ion-item class="input-wrapper"> 

                    <ion-label position="floating">
                      Minutes 
                      <span class="error" *ngIf="parseNumber(transferCandidateRecord.minutes) < 0 || parseNumber(transferCandidateRecord.minutes) > 59">
                        Invalid value
                      </span>
                    </ion-label>        
                    <ion-input type="number" inputmode="numeric" min="0" 
                      (ionFocus)="checkDecimalHours(transferCandidateRecord.candidate.candidate_id);"
                      (ionChange)="calculateTotal()"
                      [(ngModel)]="transferCandidateRecord.minutes"
                      formControlName="minutes[{{transferCandidateRecord.candidate.candidate_id}}]">
                    </ion-input>
                  </ion-item>
                </ion-col>

                <ion-col size-lg="4" size-md="4" size-sm="12" size-xs="12">
                  <ion-item class="input-wrapper"> 
                    <ion-label position="floating">
                      Seconds 
                      <span class="error" *ngIf="parseNumber(transferCandidateRecord.seconds) < 0 || parseNumber(transferCandidateRecord.seconds) > 59">
                        Invalid value
                      </span>
                    </ion-label>  
                    <ion-input type="number" inputmode="numeric" min="0" (ionChange)="calculateTotal()"
                      [(ngModel)]="transferCandidateRecord.seconds"
                      formControlName="seconds[{{transferCandidateRecord.candidate.candidate_id}}]">
                    </ion-input>
                  </ion-item>
                </ion-col>

                <!-- Bonus Input Column -->
                <ion-col size-lg="4" size-md="4" size-sm="12" size-xs="12">
                  <ion-item class="input-wrapper">
                    <ion-label position="floating">
                      Bonus 
                      <span class="error" *ngIf="parseNumber(transferCandidateRecord.bonus) < 0">Invalid value</span>
                    </ion-label>
                    <!-- [readonly]="transferCandidateRecord.candidate.civilExpired" -->
                    <ion-input  type="number" min="0" inputmode="decimal" (ionChange)="calculateTotal()"
                      [(ngModel)]="transferCandidateRecord.bonus"
                      formControlName="bonus[{{transferCandidateRecord.candidate.candidate_id}}]">
                    </ion-input>
                  </ion-item>
                </ion-col>

                <!-- Total Calculation Column -->
                <!-- *ngIf="parseNumber(transferCandidateRecord.hours) > 0" -->
                <ion-col size-lg="4" size-md="4" size-sm="12" size-xs="12">
                  <ion-item class="subtotal-wrapper">
                    <div tabindex="0"></div>
                    <ion-label>
                      <p>Subtotal</p>
                      <h5>
                        {{ form.value.currency_code }} 
                        {{ 
                          (
                            parseNumber(transferCandidateRecord.hours) 
                            * 
                            getCompanyHourlyRate(transferCandidateRecord)
                          ) 
                          + (
                            parseNumber(transferCandidateRecord.minutes) 
                            * 
                            getCompanyHourlyRate(transferCandidateRecord) / 60
                          ) 
                          + (
                            parseNumber(transferCandidateRecord.seconds) 
                            * 
                            getCompanyHourlyRate(transferCandidateRecord) / 3600
                          ) 
                          + parseNumber(transferCandidateRecord.bonus) 
                          + parseNumber(transferCandidateRecord.transfer_cost)
                          | number:'1.0-3' 
                        }}
                      </h5>
                    </ion-label>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card>
        </ion-item-group>
      </form>

      <div class="total-wrapper">
        <div class="pull-left">
          <p>Total Amount </p>
          <h4>{{ form.value.currency_code }} {{ total | number:'1.0-3' }}</h4>
        </div>
        <div class="pull-left">
          <p>Currency </p>
          <ion-select  *ngIf="form" [value]="form.value.currency_code" (ionChange)="onCurrencyUpdate($event)"> 
            <ion-select-option [value]="currency.code" *ngFor="let currency of authService.currencies">
              {{ currency.code }}
            </ion-select-option>
          </ion-select>
        </div>
        
        <ion-button class="btn-save" [disabled]="!form.valid || (total <= 0)" (click)="validate()">
          Save
        </ion-button>

        <div class="clearfix"></div>

      </div>
    </ng-container>

    <ng-container *ngIf="segment == 'excel-sheet-upload'">

      <!-- uploadedFileExtension -->
      <ion-item class="image-upload no-image">
        <div tabindex="0"></div>
        <ion-thumbnail slot="start">

          <div tappable (click)="triggerUpload($event)" class="no-logo">
            <ion-icon src="assets/images/brand-no-image.svg"></ion-icon>
          </div>

        </ion-thumbnail>
        <ion-label>
          <a id="btn-upload-pic" tappable #btnChangePhoto (click)="upload();">
            Upload Excel File
          </a>
        </ion-label>
      </ion-item>

      <div class="progress-wrapper" *ngIf="progress">
        <div class="progress-box">

          <h4>{{ "Uploading…" }} <span>{{ progress + '%' }}</span></h4>

          <div class="progress-bar">
            <div class="progress" [style.width]="progress + '%'"></div>
          </div>

          <ion-icon *ngIf="currentTarget" tappable src="assets/images/close.svg" (click)="cancelUpload()">
          </ion-icon>
        </div>
      </div>

      <a class="btn-download" tappable (click)="downloadTemplate()">
        Download Excel Template
      </a>
 
      <br />

      <a class="btn-download" tappable (click)="downloadTemplate('previousTransfer')">
        Download with previous transfer data
      </a>

      <br />

      <a class="btn-download" tappable (click)="downloadTemplate('workLog')" *ngIf="!startDate || !endDate">
        Download with current month's work log <br />
        <small>Select date to download with work log from specific period</small>
      </a>
      

      <a class="btn-download" tappable (click)="downloadTemplate('workLog')" *ngIf="startDate && endDate">
        Download with work log for selected dates
      </a>

      <!-- Upload Form -->
      <input type='file' #fileInput class="upload" (change)="browserUpload($event)" accept=".xlsx,.xls" />

    </ng-container>
  </div>
</ion-content>
