<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title>Client List</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)" class="ion-no-padding">
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">

    <ion-searchbar *ngIf="query || contactList.length > 5" [(ngModel)]="query" (ionChange)="filter($event)" placeholder="Search by name / phone / email"
      debounce="100"></ion-searchbar>

    <ion-list class="contact-list" *ngIf="contactList && contactList.length > 0">
      <ng-container *ngFor="let companyContact of contactList">
          <ion-item tappable (click)="dismiss(companyContact)">
            <div tabindex="0"></div>

            <ion-label class="ion-text-nowrap">
              <h4>{{ companyContact.contact_name }}</h4>

              <ng-container *ngIf="companyContact && companyContact.companies">
                <h5 *ngFor="let company of companyContact.companies ">
                  <strong>{{ company.company_common_name_en? company.company_common_name_en: company.company_name }}</strong>
                </h5>
              </ng-container>

              <p>{{ companyContact.contact_position }}</p>

              <p>
                <a (click)="doNothing($event)" *ngFor="let contactEmail of companyContact.contactEmails">
                  <ion-icon name="mail-outline"></ion-icon> {{ contactEmail.email_address }}
                </a>
              </p>
              <p>
                <a (click)="doNothing($event)" *ngFor="let contactPhone of companyContact.contactPhones">
                  <ion-icon name="call-outline"></ion-icon> {{ contactPhone.phone_number }}
                </a>
              </p>
            </ion-label>
            
            <ion-button (click)="login(companyContact, $event)" [disabled]="loadingLoginUrl">
              <ion-icon *ngIf="!loadingLoginUrl" name="log-in-outline"></ion-icon>
              <ion-spinner *ngIf="loadingLoginUrl"></ion-spinner>
            </ion-button>

            <ng-container *ngIf="companyContact && companyContact.companies && companyContact.companies.length > 1">
              <ion-icon slot="end" *ngIf="selectedContact != companyContact.contact_uuid" name="arrow-down-outline">
              </ion-icon>
              <ion-icon slot="end" *ngIf="selectedContact == companyContact.contact_uuid" name="arrow-up-outline">
              </ion-icon>
            </ng-container>
          </ion-item>

          <ng-container *ngIf="companyContact && companyContact.companies && companyContact.companies.length > 0">
            <div *ngIf="selectedContact == companyContact.contact_uuid">
              <ion-item tappable *ngFor="let company of companyContact.companies"
                (click)="selected(companyContact, company)">
                <div tabindex="0"></div>
                <ion-label>
                  <div tabindex="0"></div>
                  {{ company.company_common_name_en? company.company_common_name_en: company.company_name }}
                </ion-label>
              </ion-item>
            </div>
          </ng-container>
      </ng-container>
    </ion-list>
  </div>
  <ion-infinite-scroll *ngIf="currentPage < pageCount" (ionInfinite)="doInfinite($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
