<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()" class="btn-close">
        <ion-back-button icon="assets/images/icon-back.svg"></ion-back-button>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ "Upload your CV / Resume / Portfolio" }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)" dir="ltr">

    <div class="container max-width">
        <div *ngIf="!progress">

            <div class="selected-file" *ngIf="candidate.candidate_resume">

                <div class="extension">{{ uploadedFileExtension }}</div>

                <div class="fileName">{{ candidate.candidate_resume }}</div>

                <a target="_blank" (click)="$event.stopPropagation()" *ngIf="!candidate.tempLocation"
                    href="getResumeUrl(candidate)">
                    <ion-icon src="assets/images/md-arrow-down.svg"></ion-icon>
                </a>

                <a target="_blank" (click)="$event.stopPropagation()" *ngIf="candidate.tempLocation"
                    href="{{ candidate.tempLocation }}">
                    <ion-icon src="assets/images/md-arrow-down.svg"></ion-icon>
                </a>
            </div>

            <ion-button tabindex="3" class="fileUpload" full>
                <ion-icon slot="start" src="assets/images/md-cloud-upload.svg"></ion-icon>
                <span *ngIf="candidate.candidate_resume">{{ "Replace File" }}</span>
                <span *ngIf="!candidate.candidate_resume">{{ "Select file" }}</span>
                <input class="upload" type='file' #fileInput (change)="browserUpload($event)">
                <!-- .png,.jpg,.jpeg,.pdf,.doc,.docx -->
            </ion-button>

        </div>

        <div class="progress-wrapper" *ngIf="progress">
            <div class="progress-box">

                <h4>{{ "Uploading…" | translate }} <span>{{ progress + '%' }}</span></h4>

                <div class="progress-bar">
                    <div class="progress" [style.width]="progress + '%'"></div>
                </div>

                <ion-icon *ngIf="currentTarget" tappable src="assets/images/close.svg" (click)="cancelUpload()">
                </ion-icon>
            </div>
        </div>

    </div>
</ion-content>
