<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ operation }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" [disabled]="!form || !form.dirty || !form.valid || loading" (click)="save()">
        <span *ngIf="!loading">Save</span>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
    <form [formGroup]="form">
      
        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label position="floating">Name English</ion-label>
          <ion-input type="text" formControlName="name_en"></ion-input>
        </ion-item>
        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label position="floating">Name Arabic</ion-label>
          <ion-input type="text" formControlName="name_ar"></ion-input>
        </ion-item>
     
    </form>
  </div>
</ion-content>
<!--
<ion-footer class="ion-no-border">
  <div class="container">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button expand="full" color="primary" [disabled]="!form || !form.dirty || !form.valid || loading" (click)="save()">
            <span *ngIf="!loading">Save</span>
            <ion-spinner *ngIf="loading"></ion-spinner>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button expand="full" (click)="close()">
            Cancel
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>-->
