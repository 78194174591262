<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>
    <ion-title>Leave request</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" [disabled]="!form || !form.dirty || !form.valid || loading" (click)="save()">
        <span *ngIf="!loading">Save</span>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
    <form [formGroup]="form">
      
        <ion-item class="input-wrapper txt-date-range">
          <div tabindex="0"></div>
          <ion-label position="floating">Time Period</ion-label>
          <ion-input [value]="range" (click)="openCalendarPopup($event)"></ion-input>
          <ion-icon src="assets/images/icon-date-range.svg"></ion-icon>
        </ion-item>
        
        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label position="floating">Note</ion-label>
          <ion-input type="text" formControlName="note"></ion-input>
        </ion-item>
      
    </form>
  </div>
</ion-content>
