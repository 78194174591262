<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>Invite this profile</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" [disabled]="!form || !form.dirty || !form.valid || loading" (click)="save()">
        <span *ngIf="!loading">Save</span>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">
    <form [formGroup]="form" *ngIf="form">
      <ion-list>

        <h5>Select Request </h5>

        <ion-searchbar (ionChange)="onSearchInput($event)" [value]="query">
        </ion-searchbar>

          <request-listing (click)="selectRequest(request)" *ngFor="let request of activeRequests" tappable
            [request]="request" [showStatus]="false"></request-listing>

      </ion-list>

    </form>

    <ion-infinite-scroll *ngIf="currentPage < pageCount" (ionInfinite)="doInfinite($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</ion-content>

