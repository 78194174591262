<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>
  
    <ion-title>Suggest this profile</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" [disabled]="!form || !form.dirty || !form.valid || loading" (click)="save()">
        <span *ngIf="!loading">Save</span>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
    <form [formGroup]="form" *ngIf="form">
      <ion-list>

        <div [class.hidden]="form.controls['request_uuid'].value">
          <h5>Select Request </h5>

          <request-listing (click)="selectRequest(request)" *ngFor="let request of activeRequests" tappable 
            [request]="request" [showStatus]="false"></request-listing>
        </div>

        <ion-item class="flip-in-hor-bottom" [class.hidden]="!form.controls['request_uuid'].value">
          <div tabindex="0"></div>
          <ion-label position="floating">
            Reason for suggestion
          </ion-label>
          <ion-textarea formControlName="suggestion"></ion-textarea>
        </ion-item>

      </ion-list>

    </form>
  </div>
</ion-content>

