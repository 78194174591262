import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
// models
import { Contact } from '../../../../../models/contact';
import { Company } from 'src/app/models/company';
import { AnalyticsService } from 'src/app/providers/analytics.service';
// services
import { CompanyContactService } from 'src/app/providers/logged-in/company-contact.service';
import { AuthService } from 'src/app/providers/auth.service';


@Component({
  selector: 'app-company-contact-list',
  templateUrl: './company-contact-list.page.html',
  styleUrls: ['./company-contact-list.page.scss'],
})
export class CompanyContactListPage implements OnInit {

  public company: Company;

  public contacts;

  public contactList = [];

  public currentPage: number;

  public pageCount: number;

  public loading = false;

  public query = '';
  public type;

  public borderLimit = false;
  public selectedContact = null;
  public loadingLoginUrl: boolean = false; 

  constructor(
    public companyContactService: CompanyContactService,
    public analyticService: AnalyticsService,
    public authService: AuthService,
    public popupCtrl: PopoverController,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
 
    this.analyticService.page('Company Contact List Page');

    if (this.company && this.company.companyContacts) {
      this.contactList = this.company.contacts;
    } else
    if (this.company && this.company.company_id) {
      this.loadCompanyContacts();
    } else {
      this.loadData(); // load all contacts if no company given
    }
  }

  doRefresh(event) {
    if (this.company && this.company.company_id) {
      this.loadCompanyContacts();
    } else {
      this.loadData(); // load all contacts if no company given
    }

    event.target.complete();
  }

  /**
   * load company contacts
   */
  loadCompanyContacts() {

    this.loading = true;

    this.currentPage = 1;

    this.contactList = [];

    this.companyContactService.companyContacts(this.company.company_id, this.query).subscribe(response => {

      this.loading = false;

      this.contactList = response;
    },
    () => {
      this.loading = false;
    });
  }

  /**
   * load all contacts
   */
  loadData() {
    this.loading = true;

    this.currentPage = 1;

    this.contactList = [];

    this.companyContactService.list(this.currentPage, this.query).subscribe(response => {

      this.loading = false;
      this.pageCount = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));

      this.contactList = response.body;
    },
    () => {
      this.loading = false;
    });
  }

  /**
   * infinite loader on scroll
   * @param event
   */
  doInfinite(event) {
    this.loading = true;

    this.currentPage++;

    this.companyContactService.list(this.currentPage, this.query).subscribe(response => {

      this.pageCount = parseInt(response.headers.get('X-Pagination-Page-Count'));
      this.currentPage = parseInt(response.headers.get('X-Pagination-Current-Page'));

      this.contactList = this.contactList.concat(response.body);
    },
    error => { },
    () => {
      this.loading = false;
      event.target.complete();
    });
  }


  login(companyContact, event) {
    event.preventDefault();
    event.stopPropagation();

    this.loadingLoginUrl = true; 

    this.companyContactService.login(companyContact.contact_uuid).subscribe(async res => {

      this.loadingLoginUrl = false;
       
      if(res.operation == "error") {
        const alert = await this.alertCtrl.create({
          header: 'Oops',
          subHeader: this.authService.errorMessage(res.message),
          buttons: ['Okay']
        });
        alert.present();
      } else {
        window.open(res.redirect, "_blank");
      }
    });
  }

  doNothing(event) {
    event.stopPropagation();
  }

  /**
   * close popup on selection
   * @param companyContact
   * @param company
   */
  dismiss(companyContact = null, company = null) {

    /**
     * dismiss on back button clicked
     */
    if (!companyContact) {
      if (this.type == 'mod') {
        this.modalCtrl.getTop().then(o => {
          if (o) {
            o.dismiss();
          }
        });
      } else  {
        this.popupCtrl.getTop().then(overlay => {
          if (overlay) {
            this.popupCtrl.dismiss({  });
          } else {
            this.modalCtrl.dismiss({  });
          }
        });
      }
    }

    /**
     * expand if more than 1 company
     */
    if (companyContact && companyContact.companies && companyContact.companies.length > 1) {
      if (companyContact.contact_uuid && this.selectedContact == companyContact.contact_uuid) {
        this.selectedContact = null;
      } else {
        this.selectedContact = companyContact.contact_uuid;
      }
    /**
     * select if only one
     */
    } else {
      this.selected(companyContact, company);
    }
  }

  /**
   * close popup on selection
   * @param companyContact
   * @param company
   */
  selected(companyContact = null, company = null) {
    let contact = new Contact();
    contact = companyContact;

    if (company == null && companyContact && companyContact.companies) {
      contact.company = companyContact.companies[0];
    } else if (company) {
      contact.company = company;
    }

    this.popupCtrl.getTop().then(overlay => {
      if (overlay) {
        this.popupCtrl.dismiss({ contact });
      } else {
        this.modalCtrl.dismiss({ contact });
      }
    });
  }

  /**
   * filter contacts
   * @param ev
   */
  filter(ev) {

    // filter from all companies

    if (!this.company) {
      return this.loadData();
    }

    // filter from given company

    this.loading = true;

    this.contactList = [];

    this.companyContactService.companyContacts(this.company.company_id, this.query).subscribe(response => {
      this.loading = false;

      this.contactList = response;
    }, () => {
      this.loading = false;
    });
  }

  logScrolling(e) {
    this.borderLimit = (e.detail.scrollTop > 20) ? true : false;
  }
}
