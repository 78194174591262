import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CandidateWarningFormPage } from './candidate-warning-form.page';

const routes: Routes = [
  {
    path: '',
    component: CandidateWarningFormPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CandidateWarningFormPageRoutingModule {}
