<ion-card *ngIf="candidate" [class.assigned]="candidate.store">

  <ion-item lines="none">
    <div tabindex="0"></div>

    <ion-avatar slot="start">
      <img *ngIf="candidate.candidate_personal_photo"
        src="{{ aws.cloudinaryUrl + 'candidate-photo/' + candidate.candidate_personal_photo }}" onerror="this.src='/assets/icon/icon.png';">
      <img *ngIf="candidate && !candidate.candidate_personal_photo" src="/assets/icon/icon.png">
    </ion-avatar>

    <ion-label>

      <h2 class="candidate_name">
        {{ candidate.candidate_name ? candidate.candidate_name : candidate.candidate_name_ar }} <small>({{candidate.candidate_id}})</small>
        &nbsp;
        <ion-icon class="verified" src="../../assets/images/ic_verified.svg" *ngIf="candidate.isProfileCompleted" title="Completed Profile"></ion-icon>
      </h2>

      <p>{{ candidate.candidate_birth_date | candidateAge }}
        <span *ngIf="candidate.candidate_hourly_rate > 0"><span class="sep">•</span>{{ candidate.candidate_hourly_rate }} {{ candidate.currency_code }} /hour</span>
      </p>

      <p class="nationality" *ngIf="candidate.nationality">
        {{
        translateService.langContent(candidate.nationality.country_nationality_name_en,
        candidate.nationality.country_nationality_name_ar) }}
      </p>

      <p class="nationality" *ngIf="!candidate.nationality && candidate.country">
        {{
        translateService.langContent(candidate.country.country_name_en,
        candidate.country.country_name_ar) }}
      </p>

      <p *ngIf="candidate.store">
        {{ candidate.store.store_name }}

        <span *ngIf="candidate.company">@ {{ candidate.company.company_name }} </span>

        <br/>

        <small *ngIf="latestWorkStartedOn">
          Latest Work Started on {{ toDate(latestWorkStartedOn) | date }}
        </small>
      </p>
        
      <p *ngIf="application && application.requestInterview">
        <span *ngIf="application.requestInterview.status == 0">
          Requested to schedule interview at {{ toDate(application.requestInterview.interview_at) | date: 'MMM d, y, h:mm:ss a' }}
        </span>
        <span *ngIf="application.requestInterview.status == 1">
          Interview scheduled at {{ toDate(application.requestInterview.interview_at) | date: 'MMM d, y, h:mm:ss a' }}
        </span>
        <span *ngIf="application.requestInterview.status == 2">
          Interview schedule request rejected
        </span>
        <span *ngIf="application.requestInterview.status == 3">
          Interview schedule request cancelled
        </span>
      </p>
      
      <ng-container *ngIf="candidate.candidateTags">
        <ion-badge color="warning" class="candidate-tag" *ngFor="let candidateTag of candidate.candidateTags">
          {{ candidateTag.tag }}
        </ion-badge> 
      </ng-container>
      
      <ion-badge *ngIf="candidate.isProfileCompleted == false" color="danger">
        Incomplete Profile
      </ion-badge>

      <ion-badge *ngIf="candidate.civilExpired" color="danger">
        Civil Expired
      </ion-badge>

      <ion-badge *ngIf="!candidate.candidate_email_verification" color="danger">
        Email Not Verified
      </ion-badge>

      <ion-badge *ngIf="candidate.deleted" color="danger">
        Deleted
      </ion-badge>

      <ng-container *ngIf="!fromAlgolia">
        <p *ngIf="candidate.avgTimeToViewInvitations">
          Average time to view invitations ~ {{ candidate.avgTimeToViewInvitations | durationBySecond }}
        </p>
        
        <p *ngIf="!candidate.avgTimeToViewInvitations">
          No invitation viewed yet
        </p>
      </ng-container>

      <p><small>Created on: {{ toDate(candidate.candidate_created_at) | date: 'MMM d, y, h:mm:ss a'}} | Updated On: {{ toDate(candidate.candidate_updated_at) | date: 'MMM d, y, h:mm:ss a'}}</small></p>
    </ion-label>

    <ion-checkbox *ngIf="type == 'candidate'" #checkbox
      (click)="$event.preventDefault();$event.stopPropagation();" slot="end"
      (ionChange)="onCandidateSelected($event)"
      [value]="candidate.candidate_id"></ion-checkbox>

  </ion-item>

  <ion-card-header *ngIf="application && application.requestInterview 
    && application.requestInterview.status == 0">

    <ion-button (click)="acceptInterview($event, application.requestInterview)">
      Accept interview
    </ion-button>

    <ion-button color="light" (click)="rejectInterview($event, application.requestInterview)">
      Reject interview request
    </ion-button>

  </ion-card-header>
</ion-card>

