<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" defaultHref="/company-registration-request-list"></ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="request">{{ request.company_name }}</ion-title>
    <ion-buttons slot="end" *ngIf="request">
      <ion-button (click)="approve()" [disabled]="approving || rejecting">
        <ion-spinner *ngIf="approving"> </ion-spinner>
        <span *ngIf="!approving">Approve</span>
      </ion-button>
      <ion-button (click)="reject()" [disabled]="approving || rejecting">
        <ion-spinner *ngIf="rejecting"> </ion-spinner>
        <span *ngIf="!rejecting">Reject</span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
     
    <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
 
    <ion-list *ngIf="request">

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <h5>Request ID</h5>
          <p>{{ request.company_request_uuid }}</p>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <h5>Company Name</h5>
          <p>{{ request.company_name }}</p>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <h5>Email</h5>
          <p>{{ request.company_email }}</p>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <h5>Contact position</h5>
          <p>{{ request.contact_position }}</p>
        </ion-label>
      </ion-item>
 
      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <h5>Requesting for</h5>
          <p>{{ request.requesting_for }}</p>
        </ion-label>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <h5>Currency code</h5>
          <p>{{ request.currency_code }}</p>
        </ion-label>
      </ion-item>

      <ion-item *ngIf="request.country">
        <div tabindex="0"></div>
        <ion-label>
          <h5>Country</h5>
          <p>{{ request.country.country_name_en }}</p>
        </ion-label>
      </ion-item>
 
      <!--
      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <h5>Commercial licence</h5>
          <p *ngIf="request.commercial_licence">{{ aws.permanentBucketUrl + request.commercial_licence }}</p>
          <p *ngIf="!request.commercial_licence" class="txt-color-danger">No licence uploaded!</p>
        </ion-label>
      </ion-item>-->
  
    </ion-list>
    
    <h5 *ngIf="request && request.contact_name">Contact</h5>
  
    <ion-list *ngIf="request && request.contact_name">

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>
          <h5>Name</h5>
          <p>{{ request.contact_name }}</p>
        </ion-label>
      </ion-item>
  
      <ion-item *ngIf="request.phone_number">
        <div tabindex="0"></div>
        <ion-label>
          <h5>Phone number</h5>
          <p>
            {{ request.phone_number }}
          </p>
        </ion-label>
      </ion-item>

    </ion-list>

    <loading-modal [loading]="loading" type="text-list-view"></loading-modal>
    
  </div>
</ion-content>

