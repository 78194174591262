<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" [disabled]="form && !form.valid" (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
    <form [formGroup]="form" *ngIf="form">
      <ion-list>

        <ion-item class="input-wrapper"  *ngIf="['Suggested', 'Accepted', 'Rejected'].indexOf(form.controls['type'].value) == -1">
          <div tabindex="0"></div>
          <ion-label position="floating">Note Type</ion-label>
          <ion-select formControlName="type">
            <ion-select-option>Internal Note</ion-select-option>
            <ion-select-option>Phone Call</ion-select-option>
            <ion-select-option>Email</ion-select-option>
            <ion-select-option>Meeting</ion-select-option>
            <ion-select-option>Interview</ion-select-option>
            <ion-select-option>Task</ion-select-option>
          </ion-select>
        </ion-item>

        <ion-list>
          <ion-item class="input-wrapper"  (click)="openClient($event)">
            <div tabindex="0"></div>
            <ion-label position="floating">Company Name</ion-label>
            <ion-input type="text" formControlName="company_name" readonly></ion-input>
          </ion-item>
        </ion-list>

        <ion-list>
          <ion-item class="input-wrapper" (click)="openRequest($event)">
            <div tabindex="0"></div>
            <ion-label position="floating">Request</ion-label>
            <ion-input type="text" formControlName="request_name" readonly></ion-input>
          </ion-item>
        </ion-list>

        <ckeditor [config]="editorConfig" #ckeditor [editor]="Editor" (change)="onChange($event)"></ckeditor>
      
      </ion-list>
    </form>
  </div>
</ion-content>
