<ion-header mode="md">
    <ion-toolbar class="container">
        <ion-buttons slot="start">
            <ion-back-button icon="assets/images/icon-back.svg" (click)="dismiss()"></ion-back-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar class="container">
        <ion-title>Add New Document</ion-title>

        <ion-buttons slot="end">
            <ion-button clear class="cancel" color="primary" (click)="dismiss()">
                Cancel
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
    <div class="container">
        <form [formGroup]="form" *ngIf="form">
            <ion-list>
        
                <!-- uploadedFileExtension -->
                <ion-item class="image-upload" [class.have-image]="form.value.file_s3_path" [class.no-image]="!form.value.file_s3_path">
                    <div tabindex="0"></div>
                    <ion-thumbnail slot="start">
                        
                        <img tappable (click)="triggerUpdatePhoto($event)"
                            *ngIf="form.controls['file_s3_path'].value && form.controls['file_s3_path'].value.length > 0"
                            src="{{ form.controls['file_url'].value }}">

                        <div tappable (click)="triggerUpdatePhoto($event)" class="no-logo"
                            *ngIf="!form.controls['file_s3_path'].value || form.controls['file_s3_path'].value.length == 0">
                            <ion-icon src="assets/images/brand-no-image.svg"></ion-icon>
                        </div>
                        
                    </ion-thumbnail>
                    <ion-label>
                        <h5 *ngIf="form.value.file_s3_path">{{ form.controls['file_s3_path'].value }}</h5>

                        <a id="btn-download" *ngIf="form.value.file_s3_path" target="_blank" [href]="form.controls['file_url'].value">
                            <span >Download</span> 
                        </a> 
                        
                        <a id="btn-upload-pic" tappable #btnChangePhoto (click)="updatePhoto($event);">
                            <span *ngIf="form.value.file_s3_path">Change Document</span>
                            <span *ngIf="!form.value.file_s3_path">Upload Document</span>
                        </a> 
                    </ion-label>
                </ion-item>

                <div class="progress-wrapper" *ngIf="progress">
                    <div class="progress-box">
            
                        <h4>{{ "Uploading…" }} <span>{{ progress + '%' }}</span></h4>
            
                        <div class="progress-bar">
                            <div class="progress" [style.width]="progress + '%'"></div>
                        </div>
            
                        <ion-icon *ngIf="currentTarget" tappable src="assets/images/close.svg" (click)="cancelUpload()">
                        </ion-icon>
                    </div>
                </div>
            
                <ion-item class="input-wrapper">
                    <div tabindex="0"></div>
                    <ion-label position="floating">Title</ion-label>
                    <ion-input type="text" formControlName="title"></ion-input>
                </ion-item>

                <ion-item class="input-wrapper">
                    <div tabindex="0"></div>
                    <ion-label position="floating">Description</ion-label>
                    <ion-input type="text" formControlName="desc"></ion-input>
                </ion-item>
            
            </ion-list>
        </form>

        <input class="upload" type='file' #fileInput (change)="browserUpload($event)" accept=".jpg,.jpeg,.png">

        <ion-button class="btn-save" [disabled]="!form.valid" (click)="save()">
            <span *ngIf="!saving">Add New Document</span>
            <ion-spinner *ngIf="saving"></ion-spinner>
        </ion-button>
    </div>
</ion-content>