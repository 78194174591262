<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary"
                  [disabled]="!form || !form.dirty || !form.valid || loading"
                  (click)="save()">
        <span *ngIf="!loading">Save</span>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-progress-bar *ngIf="progress" type="indeterminate"></ion-progress-bar>

</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
    <form [formGroup]="form">
       
        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label position="floating">Name*</ion-label>
          <ion-input type="telephone" formControlName="name"></ion-input>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['name'].pristine">
          <p class="error" *ngIf="form.controls['name'].errors && form.controls['name'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper" > 
          <div tabindex="0"></div>
          <ion-label position="floating">Phone*</ion-label>
          <ion-input type="telephone" formControlName="phone"></ion-input>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['phone'].pristine">
          <p class="error" *ngIf="form.controls['phone'].errors && form.controls['phone'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label position="floating">Email*</ion-label>
          <ion-input type="email" formControlName="email"></ion-input>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['email'].pristine">
          <p class="error" *ngIf="form.controls['email'].errors && form.controls['email'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper"  (click)="selectNationality()">
          <div tabindex="0"></div>
          <ion-label position="floating">Nationality*</ion-label>
          <ion-input readonly formControlName="nationality"></ion-input>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['nationality_id'].pristine">
          <p class="error" *ngIf="form.controls['nationality_id'].errors && form.controls['nationality_id'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper"  tappable>
          <div tabindex="0"></div>
          <ion-label position="floating">PDF CV*</ion-label>
          <ion-input type="text" readonly formControlName="pdf_cv"></ion-input>
          <ion-buttons slot="end">

            <!-- download -->

            <ion-button *ngIf="form.controls['pdf_cv'].value || form.controls['tempPdfCVLocation'].value"
              [href]="getResumeUrl()" target="_blank">
              <ion-icon name="cloud-download-outline"></ion-icon>
            </ion-button>

            <!-- change/ upload -->

            <ion-button>
              <ion-icon name="cloud-upload-outline"></ion-icon>
              <input class="upload" accept=".pdf,.doc,.docx" type='file' #fileInput (change)="browserUpload($event)">
            </ion-button>

            <!-- remove -->

            <ion-button *ngIf="form.controls['pdf_cv'].value || form.controls['tempPdfCVLocation'].value"
              (click)="removeResume($event)">
              <ion-icon name="trash-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['pdf_cv'].pristine">
          <p class="error" *ngIf="form.controls['pdf_cv'].errors && form.controls['pdf_cv'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper"  (click)="updateLocation()">
          <div tabindex="0"></div>
          <ion-label position="floating">Location*</ion-label>
          <ion-input readonly formControlName="location"></ion-input>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['location'].pristine">
          <p class="error" *ngIf="form.controls['location'].errors && form.controls['location'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label position="floating">Current Salary</ion-label>
          <ion-input type="email" formControlName="current_salary"></ion-input>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['current_salary'].pristine">
          <p class="error" *ngIf="form.controls['current_salary'].errors && form.controls['current_salary'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper">
          <div tabindex="0"></div>
          <ion-label position="floating">Expected Salary</ion-label>
          <ion-input type="email" formControlName="expected_salary"></ion-input>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['expected_salary'].pristine">
          <p class="error" *ngIf="form.controls['expected_salary'].errors && form.controls['expected_salary'].errors.required">
            * required
          </p>
        </div>

        <!--
        <ion-item class="input-wrapper">
          <div tabindex="0"></div>
          <ion-label position="floating">Currency code</ion-label>
          <ion-select  *ngIf="form" formControlName="currency_code"> 
            <ion-select-option [value]="currency.code" *ngFor="let currency of authService.currencies">
              {{ currency.code }}
            </ion-select-option>
          </ion-select>
        </ion-item>-->

        <ion-item class="input-wrapper"  (click)="selectUniversity()">
          <div tabindex="0"></div>
          <ion-label position="floating">Select University</ion-label>
          <ion-input readonly formControlName="university"></ion-input>
        </ion-item>

  <!--      <app-select-search-input-->
  <!--        *ngIf="universitylistData"-->
  <!--        formControlName="university_id"-->
  <!--        placeholder="Select University"-->
  <!--        [collection]="universitylistData"-->
  <!--        title="University"-->
  <!--        valueAttr="university_id"-->
  <!--        labelAttr="university_name_en"-->
  <!--        altLabelAttr="university_name_ar"-->
  <!--        >-->
  <!--      </app-select-search-input>-->

        <div class="error-wrapper" *ngIf="!form.controls['university_id'].pristine">
          <p class="error" *ngIf="form.controls['university_id'].errors && form.controls['university_id'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper"  [class.ion-invalid]="!model.fulltimer_gender">
          <div tabindex="0"></div>
          <ion-label>Gender</ion-label>
          <ion-chip (click)="setGenderOption(1)" [class.active]="model.fulltimer_gender == 1">Male</ion-chip>
          <ion-chip (click)="setGenderOption(2)" [class.active]="model.fulltimer_gender == 2">Female</ion-chip>
          <ion-chip (click)="setGenderOption(3)" [class.active]="model.fulltimer_gender == 3">Other</ion-chip>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['gender'].pristine">
          <p class="error" *ngIf="form.controls['gender'].errors && form.controls['gender'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper"  [class.ion-invalid]="!model.fulltimer_driving_license">
          <div tabindex="0"></div>
          <ion-label>Driving License Available?</ion-label>
          <ion-chip (click)="setLicenseOption(1)" [class.active]="model.fulltimer_driving_license == 1">Yes</ion-chip>
          <ion-chip (click)="setLicenseOption(2)" [class.active]="model.fulltimer_driving_license == 2">No</ion-chip>
        </ion-item>

        <div class="error-wrapper" *ngIf="!form.controls['driving_license'].pristine">
          <p class="error" *ngIf="form.controls['driving_license'].errors && form.controls['driving_license'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label position="fixed">Birth Date</ion-label>
          <ion-input
            formControlName="birth_date"
            id="date"
            class="ion-text-end"
          ></ion-input>
          <app-date-popup side="top" alignment="end" [value]="model.fulltimer_birth_date" (onClose)="selectDate($event)"></app-date-popup>
        </ion-item>
        <div class="error-wrapper" *ngIf="!form.controls['birth_date'].pristine">
          <p class="error" *ngIf="form.controls['birth_date'].errors && form.controls['birth_date'].errors.required">
            * required
          </p>
        </div>

        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label>Employed?</ion-label>
          <ion-toggle slot="end" formControlName="employed"></ion-toggle>
        </ion-item>
        <div class="error-wrapper" *ngIf="!form.controls['employed'].pristine">
          <p class="error" *ngIf="form.controls['employed'].errors && form.controls['employed'].errors.required">
            * required
          </p>
        </div>

        <ion-card>
          <ion-card-header>
            <ion-card-title>Tags </ion-card-title>
          </ion-card-header>
          <ion-card-content>

            <div [formGroup]="fulltimerTag" *ngFor="let fulltimerTag of fulltimerTags.controls; let i = index">
              
              <ion-item  class="input-wrapper" >
                <div tabindex="0"></div>

                <ion-input autocomplete="off1" (ionChange)="onTagChange($event, i)" formControlName="tag"
                  enterkeyhint="Next"></ion-input>

                <ion-button fill="clear" slot="end" tabindex="-1" *ngIf="fulltimerTags.controls.length -1 != i" (click)="removeTag(i)">
                  <img src="assets/images/close.svg">
                </ion-button>

              </ion-item>
            </div>
          </ion-card-content>
        </ion-card>

        <ion-item class="flip-in-hor-bottom" *ngIf="this.request_uuid">
          <div tabindex="0"></div>
          <ion-label position="floating">
            Reason for suggestion
          </ion-label>
          <ion-textarea formControlName="suggestion"></ion-textarea>
        </ion-item>
 
    </form>
  </div>
</ion-content>
