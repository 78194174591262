<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" *ngIf="!directView" defaultHref="/"></ion-back-button>
      <ion-back-button icon="assets/images/icon-back.svg" *ngIf="directView" (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button (click)="options($event)">
        <ion-icon src="assets/icon/icon-option.svg"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
  <ion-toolbar *ngIf="store" class="container">

    <ion-title>{{ store.store_name }}</ion-title>

    <!--
    <ion-button *ngIf="!store.storeManager" class="btn-store-manager" (click)="selectStoreManager()">
      Select store manager
    </ion-button>-->

    <ion-item *ngIf="store.storeManager" class="store-manager">
      <div tabindex="0"></div>

      <ion-avatar slot="start">
        <img src="/assets/icon/icon.png">
      </ion-avatar>

      <ion-label>
        <h4>{{ store.storeManager.name }}</h4>
        <p> {{ "Store Manager" }}</p>
      </ion-label>

      <!--
      <ion-buttons slot="end">

        <ion-button color="primary" (click)="selectStoreManager()">
          <ion-icon src="assets/icon/icon-edit.svg"></ion-icon>
        </ion-button>

        <ion-button color="danger" (click)="removeStoreManager()">
          <ion-icon src="assets/icon/icon-trash.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
      -->
    </ion-item>

      <!--
      <ion-button (click)="update()">
        <ion-icon slot="start" name="pencil-outline"></ion-icon>
        Update
      </ion-button>

      <ion-button (click)="deleteStore($event, store)">
        <ion-icon slot="start" name="trash-outline"></ion-icon>
        Delete
      </ion-button>-->

  </ion-toolbar>

  <ion-progress-bar type="indeterminate" *ngIf="updating"></ion-progress-bar>

</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">

    <ion-list *ngIf="store">

      <h5>
        Staff <span>({{ store.store_total_candidates }})</span>
      </h5>

      <candidate tappable (click)="candidateSelected(candidate)" [candidate]="candidate" (refresh)="loadData()" 
        *ngFor="let candidate of store.candidates"></candidate>

    </ion-list>

    <loading-modal [loading]="loading" type="candidate-list"></loading-modal>
    
  </div>
</ion-content>
<ion-footer *ngIf="store && store.storeManager">
  <ion-toolbar class="container">
    <ion-button [disabled]="loadingLoginUrl" (click)="login()">
      <span *ngIf="!loadingLoginUrl">Login <ion-icon name="open-outline"></ion-icon></span>
      <ion-spinner *ngIf="loadingLoginUrl"></ion-spinner>
    </ion-button>
  </ion-toolbar>
</ion-footer>