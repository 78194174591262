<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar *ngIf="company" class="container head-area">
    <h1 class="title">
      Mall presence <span *ngIf="company.malls">({{company.malls.length}})</span>
    </h1>

    <h3 class="company-name">
      {{ (company.company_common_name_en) ?  company.company_common_name_en:company.company_name }}
    </h3>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)" class="ion-no-padding">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">
    <ion-list *ngIf="company">
      <ion-item tappable (click)="mallSelected(mall)" *ngFor="let mall of company.malls" lines="none">
        <div tabindex="0"></div>
        <ion-label>
          <ion-text>
            <h5>{{ mall.mall_name_en }}</h5>
          </ion-text>
          <ion-text>
            <ion-badge color="light" *ngIf="mall.store_count > 0">
              {{ mall.store_count }} stores
            </ion-badge>
            <ion-badge color="light" *ngIf="mall.candidate_count > 0">
              {{ mall.candidate_count }} staff
            </ion-badge>
          </ion-text>
        </ion-label>
      </ion-item>

    </ion-list>

    <ion-list>
      <loading-modal [loading]="loading" type="mall-list"></loading-modal>
    </ion-list>

    <no-items *ngIf="!loading && company.malls.length ==0" message="~ No mall data available ~"></no-items>
  </div>
</ion-content>
