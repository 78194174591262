import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { NoItemsComponent } from './no-items.component';

@NgModule({
  declarations: [NoItemsComponent],
  imports: [IonicModule],
  exports: [NoItemsComponent]
})
export class NoItemsModule { }