
<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <!--
    <ion-buttons slot="end">
      <ion-button clear (click)="options()">
        <ion-icon class="setting-icon" name="ellipsis-vertical-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
-->
  </ion-toolbar>
  <ion-toolbar class="container">
    <ion-title>Contacts</ion-title>
  </ion-toolbar>
  <ion-toolbar *ngIf="query || companyContacts.length > 5" class="container searchbar-wrapper">

    <ion-searchbar mode="md" [(ngModel)]="query" (ionChange)="loadContacts()" placeholder="Search by name / phone / email"
      debounce="100"></ion-searchbar>

    <ion-buttons slot="end">
      <ion-button class="setting_btn" [class.active]="filter.filter_email_unverified" (click)="openFilter()">
        <ion-icon name="options-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>
<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">
 
    <ion-chip *ngIf="filter.filter_email_unverified" (click)="clearEmailFilter()">
      Having unverified email  <ion-icon name="close-outline"></ion-icon>
    </ion-chip>  

    <!--
    <ion-chip *ngIf="filter.filter_email_unverified == 0" (click)="clearEmailFilter()">
      Having verified email  <ion-icon name="close-outline"></ion-icon>
    </ion-chip>  -->

    <ion-card *ngFor="let companyContact of companyContacts">
      
      <ion-card-header>
        <ion-item lines="none" tappable (click)="openContactDetail(companyContact, $event)">
          <div tabindex="0"></div>
          
          <ion-thumbnail *ngIf="company && company.company_logo">
            <img src="{{ aws.cloudinaryUrl + company.company_logo }}" (error)="imageError()">
          </ion-thumbnail>

          <ion-label>
            <h4>{{ companyContact.contact_name }}</h4>

            <h2>
              {{ companyContact.contact_position }}
            </h2>

            <h2 *ngIf="companyContact.contact_position">
              {{ companyContact.contact_position }}
            </h2>
 
            <ion-badge *ngIf="!companyContact.contact_email">
              Missing contact's primary email 
            </ion-badge>  
            
            <ion-badge *ngIf="companyContact.contact_email && (!companyContact.contact_email_verification || companyContact.contact_email_verification =='0')">
             Email not verified
            </ion-badge>  

          </ion-label>

          <ng-container *ngIf="companyContact.contact_email && (!companyContact.contact_email_verification || 
            companyContact.contact_email_verification == '0')">
            <ion-button class="btn-email-verified" [disabled]="markingEmailVerified" (click)="markEmailVerified(companyContact, $event)">
              <ion-spinner *ngIf="markingEmailVerified"></ion-spinner>
              <span *ngIf="!markingEmailVerified">Mark email verified </span>
            </ion-button>

            <ion-button class="btn-email-verified" [disabled]="sendingVerificationMail" (click)="sendVerificationMail(companyContact, $event)">
              <ion-spinner *ngIf="sendingVerificationMail"></ion-spinner>
              <span *ngIf="!sendingVerificationMail">Send Verification email</span>
            </ion-button>
            
          </ng-container>
          
          <ion-button slot="end" class="btn-login" (click)="login(companyContact, $event)" [disabled]="loadingLoginUrl">
            <ion-icon *ngIf="!loadingLoginUrl" name="log-in-outline"></ion-icon> 
            <ion-spinner *ngIf="loadingLoginUrl"></ion-spinner>
          </ion-button>

          <ion-button slot="end" (click)="toggleOpen(companyContact, $event)" [class.is-open]="companyContact.isOpen">
            <ion-icon src="assets/images/icon-arrow-down.svg"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-card-header>
      <ion-card-content *ngIf="companyContact.isOpen">
        
        <p *ngIf="companyContact.contact_email">
          <a href="mailto:{{ companyContact.contact_email }}" (click)="doNothing($event)">
            <ion-icon src="assets/images/icon-email.svg"></ion-icon> {{ companyContact.contact_email }} <strong class="not-verified" *ngIf="companyContact.contact_email_verification == '0'"> &nbsp;&nbsp;Not Verified</strong>
          </a>
        </p>

        <p>
          <a href="mailto:{{ contactEmail.email_address }}" (click)="doNothing($event)"
            *ngFor="let contactEmail of companyContact.contactEmails">
            <ion-icon src="assets/images/icon-email.svg"></ion-icon> {{ contactEmail.email_address }}
          </a>
        </p>

        <p>
          <a href="tel:{{ contactPhone.phone_number }}" (click)="doNothing($event)"
            *ngFor="let contactPhone of companyContact.contactPhones">
            <ion-icon src="assets/images/icon-phone.svg"></ion-icon> {{ contactPhone.phone_number }}
          </a>
        </p>

        <!--
        <p>
          <a *ngIf="companyContact.contactStats.lastNotes">
            <ion-icon name="time-outline"></ion-icon> Last note was {{ companyContact.contactStats.lastNotes.note_updated_datetime | timeAgo }}
          </a>
        </p>

        <div class="counts">
          <ion-badge>
            {{ companyContact.contactStats.requests }} requests
          </ion-badge>
          <ion-badge>
            {{ companyContact.contactStats.notes }} notes
          </ion-badge>
        </div>
        -->
      </ion-card-content>
    </ion-card>

    <loading-modal type="contact-list" [loading]="loading"></loading-modal>

    <no-items *ngIf="!loading && companyContacts.length ==0" message="~ No Contact data ~"></no-items>
    
    <ion-infinite-scroll *ngIf="currentPage < pageCount" (ionInfinite)="doInfinite($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
 
  </div>

  <ion-fab *ngIf="company" vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="addCompanyContact()">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

</ion-content>
