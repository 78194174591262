<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title>Company Request List</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">
    <ion-list>
      <request-listing *ngFor="let request of requests" (click)="rowSelected(request)"
                      [request]="request" [showStatus]="false"></request-listing>
    </ion-list>
    
    <loading-modal type="text-list" [loading]="loading"></loading-modal>

    <no-items message="~ No request found ~" *ngIf="!loading && requests.length == 0"></no-items>

    <ion-infinite-scroll *ngIf="currentPage < pageCount" (ionInfinite)="doInfinite($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</ion-content>
