<ion-header mode="md" [class.add-border]="borderLimit" mode="md">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
<!--    <ion-buttons slot="end">-->
<!--      <ion-button><ion-icon class="setting-icon" name="ellipsis-vertical-outline"></ion-icon></ion-button>-->
<!--    </ion-buttons>-->
  </ion-toolbar>
  <ion-toolbar class="container">
    <ion-title class="main" size="large">
      Documents <ng-container *ngIf="company && company.files">({{ company.files.length }})</ng-container>
      </ion-title>
    <ion-title class="secondary" size="small" *ngIf="company">
      {{ (company.company_common_name_en) ?  company.company_common_name_en:company.company_name }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">
    <div *ngIf="company && company.files">
      <ion-item target="_blank" href="{{ awsService.permanentBucketUrl }}{{ file.file_s3_path }}" *ngFor="let file of company.files">
        <div tabindex="0"></div>
        <ion-thumbnail slot="start">
          <img class="icon" src="assets/images/ic_save.svg" />
        </ion-thumbnail>
        <ion-label>
          <h5>{{ file.file_title }}</h5>
          <p class="date">Uploaded on {{ toDate(file.file_created_datetime) | date: 'MMM d, y' }}</p>
        </ion-label>

        <ion-note slot="end">{{ file.file_size | filesize }}</ion-note>

        <!-- <span class="meta-data">{{ getFileType(file) }}</span>
        <p class="document-detail">{{file.file_description}}</p>-->
      </ion-item>
    </div>

    <loading-modal [loading]="loading" type="company-file-list"></loading-modal>

    <no-items *ngIf="!loading && company && company.files && company.files.length == 0" message="~ No Document data ~"></no-items>
 
  </div>

  <ion-fab *ngIf="company" vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="uploadDocument()">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
