<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" defaultHref="/" (click)="dismiss($event)"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="container">
    <ion-title>Select Store Manager</ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        Cancel
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
 
      <ion-item *ngFor="let companyContact of companyContacts" tappable (click)="rowSelected(companyContact)">
        <div tabindex="0"></div>
        
        <ion-avatar slot="start">
          <img src="/assets/icon/icon.png">
        </ion-avatar>

        <ion-label>
          <h4>{{ companyContact.contact_name }}</h4>
          <p>
            {{ companyContact.contact_position }}
          </p>
        </ion-label>
      </ion-item>

      <ion-item *ngFor="let companyContact of parentCompanyContacts" tappable (click)="rowSelected(companyContact)">
        <div tabindex="0"></div>
        
        <ion-avatar slot="start">
          <img src="/assets/icon/icon.png">
        </ion-avatar>

        <ion-label>
          <h4>{{ companyContact.contact_name }}</h4>
          <p>
            {{ companyContact.contact_position }}
          </p>
        </ion-label>
      </ion-item>

      <loading-modal [loading]="loading" type="text"></loading-modal>

      <h2 class="no-result-found" *ngIf="parentCompanyContacts.length + companyContacts.length == 0 && !loading">
        No contacts added yet!
      </h2> 

  </div>
</ion-content>
