<ion-content>
  <ion-item tappable (click)="action('edit')">
    <div tabindex="0"></div>
   <ion-icon src="assets/icon/icon-edit-2.svg"></ion-icon> Edit Store
  </ion-item>

  <ion-item tappable (click)="action('delete')">
    <div tabindex="0"></div>
    <ion-icon src="assets/icon/icon-trash-2.svg"></ion-icon> Delete Store
  </ion-item>

</ion-content>
