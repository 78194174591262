<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start" (click)="dismiss()">
      <ion-back-button icon="assets/images/icon-back.svg"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ "Skills" }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">

  <div class="container max-width">
      <div class="candidateSkills" *ngIf="skillList">

        <ion-item class="input-wrapper"  *ngFor="let s of tmpSkill;let i=index;">
          <div tabindex="0"></div>
          
          <ion-input id="input[{{ i }}]" placeholder="{{ 'Type a skill' }}"
            [(ngModel)]="skillList[i]" (ionFocus)="validateSkills()"
            (ionChange)="change($event, i, s)" type="text" enterkeyhint="{{ 'Next'}}" (keyup)="nextOnEnter($event, i)"></ion-input>

          <ion-button fill="clear" tabindex="-1" item-right *ngIf="skillList[i]" fill="clear" (click)="removeSkill(i,s)"
            class="skip-hover">
            <img src="assets/images/ic_circledCancel.svg">
          </ion-button>
        </ion-item>
      </div>

      <ion-button (click)="save()" class="btn-submit" [disabled]="skillList.length < 1 || loading" type="submit">
        Done
      </ion-button>

  </div>
</ion-content>
