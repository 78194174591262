<ng-container *ngIf="request">
  <ion-card [class.pending]="request.request_status == 'pending'" tappable>
    <ion-card-content>
      <div class="inner-card-content">
        <h1>({{ request.request_number_of_employees }}) {{request.request_position_title}}
        </h1>

        <h3 *ngIf="request.company">
          {{ request.company.company_common_name_en? request.company.company_common_name_en: request.company.company_name }}
          <ion-icon name="checkmark-outline"  *ngIf="request.company && request.company.company_approved_to_hire"></ion-icon>
          <ion-icon name="close-outline"  *ngIf="request.company && !request.company.company_approved_to_hire"></ion-icon>
        </h3>

        <small class="time">{{ request.request_created_datetime | timeAgo }} </small>

        <p *ngIf="request.request_additional_info" class="txt-additional-info">{{request.request_additional_info.substr(0,85)}}...</p>

        <div>
          
          <ion-badge *ngIf="request.nationality">{{ request.nationality.country_nationality_name_en }}</ion-badge>

          <ion-badge *ngIf="request.gender == 0">Any gender</ion-badge>
          <ion-badge *ngIf="request.gender == 1">Male</ion-badge>
          <ion-badge *ngIf="request.gender == 2">Female</ion-badge>
          <ion-badge *ngIf="request.gender == 3">Othe gender</ion-badge>

          <ion-badge *ngIf="request.request_position_type == 1">Full-time</ion-badge>
          <ion-badge *ngIf="request.request_position_type == 2">Part-time</ion-badge>
          
          <ion-badge *ngIf="request.request_location">{{request.request_location.length > 30 ? request.request_location.substr(0,30)+'...' : request.request_location }}</ion-badge>

          <!-- for old requests -->
          <ng-container *ngIf="request.storyOwners && request.storyOwners.length == 0">
            <ion-badge *ngFor="let staff of request.staffs">
              {{ staff.staff_name }}
            </ion-badge>
          </ng-container>

          <ion-badge class="status">
            <span *ngIf="request.request_status == 'started'">Started</span>
            <span *ngIf="request.request_status == 'delivered'">Delivered</span>
            <span *ngIf="request.request_status == 'cancelled'">Cancelled</span>
            <span *ngIf="request.request_status == 're_work'">Re Work</span>
            <span *ngIf="request.request_status == 'pending'">Pending</span>
            <span *ngIf="request.request_status == 'finished_by_recruitment'">Finished by recruitment</span>
          </ion-badge>
          
          <ion-badge>
            Stories : {{request.story_count}}
          </ion-badge>
          <ion-badge>
            Required : {{request.request_number_of_employees}}
          </ion-badge>
          <ion-badge *ngIf="request.invited">
            Invited : {{ request.invited }}
          </ion-badge>
          <ion-badge *ngIf="request.suggestion">
            Suggested : {{request.suggestion}}
          </ion-badge>

          <ng-container *ngIf="request.company">
            <ion-badge>{{ request.company.currency_code }} </ion-badge>
            <ion-badge *ngIf="request.company.country">{{ request.company.country.country_name_en }} </ion-badge>        
          </ng-container>
          
        </div>

        <p *ngFor="let staff of request.storyOwners" class="working">{{ staff.staff_name }} is working on it</p>

<!--        <ion-button size="small" (click)="deliverRequest($event,request)" *ngIf="request.request_status == 'finished_by_recruitment'">Deliver</ion-button>-->
        <ion-button size="small" (click)="finishRequest($event,request)"  *ngIf="(request.request_status != 'delivered' && request.request_status != 'finished_by_recruitment')">Finished By Recruiter</ion-button>
        <ion-button size="small" (click)="reworkRequest($event,request)" *ngIf="request.request_status == 'finished_by_recruitment' || request.request_status == 'delivered'">Rework</ion-button>
        <ion-button size="small" (click)="createStory($event,request)" *ngIf="(request.request_status != 'finished_by_recruitment' && request.request_status != 'delivered')">Create Story</ion-button>

      </div>

      <div *ngIf="request" class="content-footer ion-text-center" [class.background-grey]="active" [class.background-red]="!active" [class.background-green]="request.request_status == 'delivered'">

        <p *ngIf="request.our_fees > 0">
          {{ request.our_fees + " " + request.company.currency_code + " / " + request.our_fees_unit }}
        </p> 

        <p *ngIf="request.request_started_at && request.staff">
          Started {{ request.request_started_at | timeAgo }} By {{ request.staff.staff_name }}
        </p>

        <p *ngIf="request.request_status == 'delivered'">Delivered {{ request.request_updated_datetime | timeAgo }} </p>
        <p *ngIf="request.request_status == 'cancelled'">Cancelled {{ request.request_updated_datetime | timeAgo }} </p>
        <p *ngIf="active && request.request_status == 'started'">Last updated {{ request.request_updated_datetime | timeAgo }} </p>
        <p *ngIf="active && request.request_status == 'pending'">Last updated {{ request.request_updated_datetime | timeAgo }} </p>
        <p *ngIf="!active && late">{{ getLateTime(late)}}</p>

        <p *ngIf="request.request_started_at && request.request_cancelled_at">
          Cancelled after {{ request.request_started_at | duration : translateService.currentLang : request.request_cancelled_at }}
        </p>

        <p *ngIf="request.request_started_at && request.request_delivered_at">
          Took {{ request.request_started_at | duration : translateService.currentLang : request.request_delivered_at }} to complete
        </p>

      </div>
    
    </ion-card-content> 
  </ion-card>
</ng-container>
