<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="addStore()">
        Add a store
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  
  <div class="container">
    <ion-list *ngIf="company && company.stores">

      <h3 class="title">
        Stores
      </h3>

      <h4 class="company-name">
        {{ (company.company_common_name_en) ? company.company_common_name_en:company.company_name }}
      </h4>

      <app-store tappable (onUpdate)="loadData()" (onDelete)="loadData()" (click)="storeSelected(store)" 
        *ngFor="let store of company.stores" [store]="store"></app-store> 

    </ion-list>

    <no-items *ngIf="!loading && company.stores && company.stores.length == 0" message="~ No stores data ~"></no-items>

    <loading-modal [loading]="loading" type="store-card-list"></loading-modal>
  </div>
</ion-content>
