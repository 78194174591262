export class StoreManager {
    store_manager_uuid: string;
    company_id: number;
    store_id: number;
    name: string;
    email: string;
    phone_number: string;
    password: string;
    password_hash: string;
    auth_key: string;
    email_verification: boolean;
    created_at: string;
    updated_at: string;
}