<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ pageTitle }}</ion-title>
    
    <ion-buttons slot="end">
      <ion-select *ngIf="transfer" [(ngModel)]="transfer.currency_code">
        <ion-select-option [value]="currency.code" *ngFor="let currency of authService.currencies">
          {{ currency.code }}
        </ion-select-option>
      </ion-select>
    </ion-buttons>
    
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center" scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">

    <h4>I am transferring for work done in the following time period:</h4>

    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <ion-item>
            <div tabindex="0"></div>
            <ion-label position="stacked">Start date</ion-label>
            <ion-datetime presentation="date" [(ngModel)]="start_date"></ion-datetime>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <div tabindex="0"></div>
            <ion-label position="stacked">End date</ion-label>
            <ion-datetime presentation="date" [(ngModel)]="end_date"></ion-datetime>    
          </ion-item>
        </ion-col>
        <!--
        <ion-col size="4" *ngIf="startDate || endDate">
      
          <ion-button fill="clear" (click)="clearSelection()">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </ion-col>-->
      </ion-row>
    </ion-grid>

    <!-- Instructions for Create -->
    <section *ngIf="scenario == 'create'">

      <h4>Step 1: Fill Start and End Date</h4>

      <h4>Step 2: Download Excel template</h4>

      <ion-button fill="outline" color="primary" (click)="downloadTemplate()">
        Download template
      </ion-button>
      
      <ion-button color="primary" (click)="downloadTemplate('previousTransfer')">
        Download with previous transfer data
      </ion-button>

      <ion-button color="primary" (click)="downloadTemplate('workLog')">
          Download with current month's work log
      </ion-button>

      <h4>Step 3: Fill out the number of hours worked and the bonus amount to be sent</h4>

      <h4>Step 4: Upload the updated Excel file</h4>
    </section>

    <br/>

    <!-- Instructions for Update -->
    <section *ngIf="scenario == 'update'">
      <h4>Upload the updated Excel file</h4>
    </section>

    <!-- Upload Form -->
    <input type='file' #fileInput (change)="browserUpload($event)" accept=".xlsx,.xls"/>

    <ion-button (click)="upload()" [disabled]="uploading || !start_date || !end_date">
      <span *ngIf="!uploading">Upload</span>
      <ion-spinner *ngIf="uploading">Uploading...</ion-spinner>
    </ion-button>
  </div>
</ion-content>
